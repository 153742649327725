import React from 'react';

import './sectionCard.scss';

interface SectionCardProps {
    LogoSvg?: any;
    title: string;
    subtitle: string;
    color: string;
}

const SectionCard: React.FC<SectionCardProps> = ({ LogoSvg, title, subtitle, color }) => {

    if (!LogoSvg) {
        return (
            <div className="_sectionCard noLogo" style={{ backgroundColor: color }}>
                <h2>
                    {title}
                </h2>
                <p>
                    {subtitle}
                </p>
            </div>
        )
    }
    return (
        <div className="_sectionCard" style={{ backgroundColor: color }}>
            {<LogoSvg />}
            <h2>
                {title}
            </h2>
            <p>
                {subtitle}
            </p>
        </div>
    )
}

export default SectionCard;