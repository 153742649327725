// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import './index.scss'
import LogoWide from "../../components/images/logowide"
import ShopifyLogo from "../../components/images/shopify"
import AmazonLogo from "../../components/images/amazon"
import MercadoLibreLogo from "../../components/images/mercadolibre"
import SectionCard from "../../components/widgets/sectionCard"

type DataProps = {
  site: {
    buildTime: string
  }
}

const EcommerceLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
  <Layout isApi={true} location={location}>
    <SEO title="FiscalPOP API Facturacioón para desarrolladores" />
    <div id='ApiIndex'>

      <div className="title">
        <div className="logos">
          <LogoWide format={'icon'} />
          <LogoWide format={'text'} />
        </div>
        <h1>
          API
      </h1>
      </div>
      <div className="subtitles">
        <h4>
          Integración global, sin complicaciónes ni librerías
        </h4>
        <h5 className="subt">
          Encuentra lo que necesites para comenzar a integrar nuestra API
          a tu plataforma utilizando 100% REST con JSON.
        </h5>
      </div>
      <div className="integrations">
        <div className="option">
          <Link to="/api/referencia">
            <SectionCard title="Referencias API" subtitle="Front-end, back-end, WebServices" color="#ff9a3c" />
          </Link>
        </div>
        <div className="option">
          <Link to="/api/guia">
            <SectionCard title="Guía CSD" subtitle="Front-end, back-end, WebServices" color="#fc8211" />
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default EcommerceLanding

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
